<template>
  <div
    class="fastDelivery"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <div class="fastDeliveryBox">
      <!-- 平台选择 -->
      <platform-select
        :platform="platform"
        @change="changePlatform"
      ></platform-select>
      <fb
        v-if="platform == 'facebook'"
        :data="data"
        :platform="platform"
        @setLoading="setLoading"
      ></fb>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { adsConsts } from '@/api/common/account';
import PlatformSelect from './components/PlatformSelect';
import fb from './components/fb';
import { getData } from '@/api/fastDelivery';
export default {
  components: {
    PlatformSelect,
    fb,
  },
  data() {
    return {
      platform: 'facebook',
      data: {
        sample_videos: [],
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters('base', {
      adsConsts: 'adsConsts',
    }),
  },
  methods: {
    getData() {
      getData({ mixcut_id: this.$route.query.taskId }).then((res) => {
        if (res.code == 0) {
          if (this.$route.query.ids) {
            const ids = this.$route.query.ids.split(',');
            res.data.sample_videos = res.data.sample_videos.filter((item) => ids.includes(`${item.id}`));
          }
          this.data = res.data;
        }
      });
    },
    // 修改平台
    changePlatform(val) {
      this.platform = val;
    },
    setLoading(bool = true) {
      this.loading = bool;
    },
  },
  created() {
    this.getData();
    if (Object.keys(this.adsConsts).length == 0) {
      adsConsts().then((res) => {
        if (res.code == 0) {
          this.$store.commit('base/setAdsConsts', res.data);
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.fastDelivery {
  width: 100%;
  max-width: 2000px;
  padding: 20px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  .fastDeliveryBox {
    height: 100%;
    overflow: scroll;
  }
}
</style>
