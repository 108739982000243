<template>
  <el-card
    header="广告资产"
    class="property mt-20"
  >
    <el-form
      :model="adAsset"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-position="top"
    >
      <div class="flex_form">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item
              label="个号"
              prop="user_id"
            >
              <el-select
                filterable
                placeholder="请选择个号"
                v-model="adAsset.user_id"
                @change="changeUserId"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in myLoginList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="广告账户"
              prop="account_id"
            >
              <el-select
                filterable
                placeholder="请选择广告账户"
                v-model="adAsset.account_id"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in accountList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="公共主页"
              prop="fb_page_id"
            >
              <el-select
                filterable
                placeholder="请选择公共主页"
                v-model="adAsset.fb_page_id"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in accountPageList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="行动号召力"
              prop="call_to_action"
            >
              <el-select
                placeholder="请选择创意行动号召力"
                v-model="adAsset.call_to_action"
              >
                <el-option
                  :label="item.desc"
                  :value="item.code"
                  v-for="(item, index) in adsConsts.callToAction"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item
              label="像素"
              prop="pixel_id"
            >
              <el-select
                filterable
                placeholder="请选择像素"
                v-model="adAsset.pixel_id"
              >
                <el-option
                  :label="item.name"
                  :value="item.pixel_id"
                  v-for="(item, index) in accountPixelList"
                  :key="item.pixel_id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="像素事件"
              prop="pixel_enent"
            >
              <el-select
                filterable
                placeholder="请选择像素事件"
                v-model="adAsset.pixel_enent"
              >
                <el-option
                  :label="item.event.title"
                  :value="item.event.customEventType"
                  v-for="(item, index) in pixelStatsList"
                  :key="item.event.customEventType"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="站点"
              prop="site_id"
            >
              <el-select
                filterable
                clearable
                remote
                :remote-method="getQueryDomainByConditionListDebounced"
                placeholder="请选择站点"
                v-model="adAsset.site_id"
                :loading="siteIdLoading"
              >
                <el-option
                  :label="item.domain"
                  :value="item.collId"
                  v-for="(item, index) in queryDomainByConditionList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="站点分类"
              prop="collCateId"
            >
              <el-select
                filterable
                placeholder="请选择站点分类"
                v-model="adAsset.collCateId"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in siteCategoryList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </el-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import {
  accountList,
  myLoginList,
  accountPageList,
  AccountPixelList,
  queryDomainByConditionList,
  getSiteCategoryList,
  pixelStatsList,
  websiteUrlCheck,
} from '@/api/fastDelivery';
export default {
  props: {
    adAsset: {
      type: Object,
      default: () => ({}),
    },
    selectCollMes: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      myLoginList: [], // 个号列表
      accountList: [], //广告账户
      accountPixelList: [], // 像素列表
      pixelStatsList: [], // 像素事件
      accountPageList: [], // 公共主页
      callToActionList: [], // 行动号召力
      queryDomainByConditionList: [], // 站点
      siteCategoryList: [], //  站点分类
      rules: {
        collCateId: [{ required: true, message: '请选择站点分类', trigger: ['blur'] }],
        site_id: [{ required: true, message: '请选择站点', trigger: ['blur'] }, { validator: this.websiteUrlCheck }],
        user_id: [{ required: true, message: '请选择个号', trigger: ['blur'] }],
        account_id: [{ required: true, message: '请选择广告账户', trigger: ['blur'] }],
        pixel_id: [{ required: true, message: '请选择像素', trigger: ['blur'] }],
        pixel_enent: [{ required: true, message: '请选择像素事件', trigger: ['blur'] }],
        fb_page_id: [{ required: true, message: '请选择公共主页', trigger: ['blur'] }],
        call_to_action: [{ required: true, message: '请选择行动号召力', trigger: ['blur'] }],
      },
      siteIdLoading: false, // 加载站点中
      checkUrlSuccess: false, // 判断域名是否合规，反正重复调用检测接口
    };
  },
  methods: {
    // 个号列表
    getMyLoginList() {
      myLoginList().then((res) => {
        if (res.code == 0) {
          this.myLoginList = res.data;
        }
      });
    },
    // 广告账户
    getAccountList() {
      accountList(this.adAsset.user_id).then((res) => {
        if (res.code == 0 || res.code == 200) {
          this.accountList = res.data.list;
        }
      });
    },
    // 修改个号
    changeUserId() {
      this.$emit('changeAdAsset', 'account_id', null);
      this.getAccountList();
    },
    // 主页列表
    getAccountPageList() {
      accountPageList(
        {
          accountId: this.adAsset.account_id,
        },
        this.adAsset.user_id,
      ).then((res) => {
        if (res.code == 0) {
          this.accountPageList = res.data;
          if (res.data.length == 0) {
            this.$message.error('该账户下没有主页，请换一个账户');
          }
        }
      });
    },
    // 像素列表
    getAccountPixelList() {
      AccountPixelList({ accountId: this.adAsset.account_id }, this.adAsset.user_id).then((res) => {
        if (res.code == 0) {
          this.accountPixelList = res.data;
        }
      });
    },
    // 像素事件
    getPixelStatsList() {
      pixelStatsList({ pixelId: this.adAsset.pixel_id }, this.adAsset.user_id).then((res) => {
        if (res.code == 0) {
          this.pixelStatsList = res.data.stats;
        }
      });
    },
    // 站点查询
    getQueryDomainByConditionList(query) {
      if (query == '') return;
      this.siteIdLoading = true;
      queryDomainByConditionList({ domain: query })
        .then((res) => {
          if (res.code == 0) {
            this.queryDomainByConditionList = res.data;
          }
        })
        .finally(() => {
          this.siteIdLoading = false;
        });
    },
    // 站点分类
    getSiteCategoryList() {
      getSiteCategoryList({ collId: this.adAsset.site_id }).then((res) => {
        if (res.code == 0) {
          this.siteCategoryList = res.data;
        }
      });
    },
    // 推广链接检测
    websiteUrlCheck(rule, val, callback) {
      if (this.adAsset.account_id && this.adAsset.site_id && !this.checkUrlSuccess) {
        let params = {
          accountId: this.adAsset.account_id,
          siteId: this.selectCollMes.collId,
        };
        websiteUrlCheck(params, this.adAsset.user_id).then((res) => {
          if (res.code == 0 && res.data.level == 'error') {
            this.checkUrlSuccess = false;
            return callback && callback(new Error(res.data.message));
          }
          callback && callback();
          this.checkUrlSuccess = true;
        });
      } else {
        callback && callback();
      }
    },
    // 验证表单
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters('base', {
      adsConsts: 'adsConsts',
    }),
  },
  created() {
    this.getMyLoginList();
    this.getQueryDomainByConditionListDebounced = _.throttle(this.getQueryDomainByConditionList, 600);
  },
  watch: {
    // 监听广告账户改变
    'adAsset.account_id': function (val) {
      // 清空主页
      this.accountPageList = [];
      this.checkUrlSuccess = false;
      this.$emit('changeAdAsset', 'fb_page_id', null);
      // 清空像素
      this.accountPixelList = [];
      this.$emit('changeAdAsset', 'pixel_id', null);

      if (val) {
        this.getAccountPageList();
        this.getAccountPixelList();
        this.websiteUrlCheck();
      }
    },
    // 像素改变
    'adAsset.pixel_id': function (val) {
      this.pixelStatsList = [];
      this.$emit('changeAdAsset', 'pixel_enent', null);
      if (val) {
        this.getPixelStatsList();
      }
    },
    // 站点改变
    'adAsset.site_id': function (val) {
      this.siteCategoryList = [];
      this.checkUrlSuccess = false;
      this.$emit('changeAdAsset', 'collCateId', null);
      const select = this.queryDomainByConditionList.find((item) => item.collId == val);
      this.$emit('changeSelectCollMes', select || {});
      if (val) {
        this.getSiteCategoryList();
      }
    },
  },
};
</script>
<style lang="scss" scope>
.property {
  ::v-deep.flex_form {
    .el-form-item__label {
      padding: 0;
      &::before {
        position: relative;
        left: -10px;
        margin-right: -5px !important;
      }
    }
    .el-input {
      width: 250px;
    }
    .el-form-item {
      padding-left: 12px;
    }
  }
}
</style>
