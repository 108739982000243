<template>
  <div class="preview">
    <el-carousel
      height="530px"
      indicator-position="outside"
    >
      <div class="img">
        <el-carousel-item
          v-for="(item, index) in previewList"
          :key="index"
        >
          <div class="title">
            <el-tooltip
              class="item"
              effect="dark"
              :disabled="!item.message"
              placement="top-start"
            >
              <pre
                slot="content"
                style="max-width: 600px; word-wrap: break-word; white-space: break-spaces"
                >{{ item.message }}</pre
              >
              <p class="tit ellipsis">{{ item.message }}</p>
            </el-tooltip>
          </div>
          <div class="imgCon">
            <el-image
              :src="item.image_url"
              alt=""
              v-if="!item.url"
              class="newImg"
              fit="contain"
            />
            <video
              :src="item.url"
              :poster="item.cover"
              v-else
              controls
            ></video>
          </div>
          <div class="pr_content">
            <div class="contentMsg">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.title"
                placement="top-start"
              >
                <p class="content ellipsis">{{ item.title }}</p>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.description"
                placement="top-start"
              >
                <p class="translate ellipsis">{{ item.description }}</p>
              </el-tooltip>
            </div>
          </div>
        </el-carousel-item>
      </div>
    </el-carousel>
  </div>
</template>
<script>
export default {
  props: {
    previewList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scope lang="scss">
.preview {
  text-align: center;
  .titleCon {
    display: flex;
    align-items: center;
    .el-image {
      width: 40px;
      height: 40px;
      margin-left: 10px;
      border-radius: 20px;
      overflow: hidden;
    }
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .title {
    padding: 10px;
    text-align: left;
    color: #606266;
    font-size: 14px;
    line-height: 20px;
    .tit {
      height: 40px;
    }
  }
  .imgCon {
    width: 320px;
    height: 320px;
    .newImg,
    video {
      width: 100%;
      height: 100%;
      .el-iamage {
        width: 100%;
        height: 100%;
      }
    }
  }

  .pr_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    .contentMsg {
      flex: 1;
      overflow: hidden;
      text-align: left;
      overflow: hidden;
      font-size: 12px;
      padding: 10px;
      .translate {
        font-size: 12px;
        color: #999;
        margin-top: 5px;
      }
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .btn {
      margin-left: 10px;
      background: #e4e6ea;
      border-radius: 2px;
      padding: 5px;
      font-size: 12px;
      a {
        color: #333333;
      }
    }
  }
  .el-carousel__container {
    position: relative;
  }
  .el-carousel {
    margin: 10px 0;
  }
  .el-carousel__button {
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
}
</style>
