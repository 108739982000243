<template>
  <el-card
    header="商品信息"
    class="sale mt-20"
  >
    <div v-loading="loading">
      <el-input
        v-model="adId"
        placeholder="请输入商品id，回车确定"
        maxlength="10"
        @input="adId = adId.replace(/\D/g, '')"
        @keydown.native.enter="(e) => getSaleInfo(e.target.value)"
        @blur="(e) => getSaleInfo(e.target.value)"
      ></el-input>
      <div
        v-show="info.error"
        class="color-warning mt-10 f-12"
      >
        {{ info.error }}
      </div>
      <div
        class="dp-flex mt-20"
        v-show="info && !info.error"
      >
        <el-image
          class="img mr-10"
          fit="contain"
          :src="info.sale.imageUrl"
        />
        <div class="dp-flex flex-d-c justify-around info">
          <div>{{ info.sale.productName }}({{ info.sale.title }})</div>
          <div>
            <span class="mr-20">商品Id: {{ info.sale.saleId }}</span>
            <span class="mr-20">产品Id: {{ info.sale.productId }}</span>
            <span>币种: {{ info.sale.currency ? info.sale.currency.desc : '--' }}</span>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import { promoteableSale } from '@/api/fastDelivery';

export default {
  props: {
    recommendSale: String | Number,
    pro_id: String | Number,
  },
  data() {
    return {
      adId: '',
      info: { sale: {} },
      loading: false,
    };
  },
  methods: {
    getSaleInfo(sale_id) {
      console.log('this.info.sale', this.info.sale, sale_id);
      if (!sale_id || (this.info.sale && this.info.sale.saleId == sale_id)) return;
      this.loading = true;
      promoteableSale({
        sale_id: Number(sale_id),
      })
        .then((res) => {
          if (res.code == 0) {
            if (res.data.error) {
              this.info = {
                ...res.data,
              };
              this.$emit('changeRecommendSale', '');
            } else {
              if (res.data.sale.productId != this.pro_id) {
                this.info = {
                  ...res.data,
                  error: `仅支持输入产品ID：${this.pro_id}对应的商品ID`,
                };
                this.$emit('changeRecommendSale', '');
              } else {
                this.info = { ...res.data };
                this.$emit('changeRecommendSale', sale_id);
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    recommendSale: {
      handler(val) {
        if (val) {
          this.adId = val;
          this.getSaleInfo(val);
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.sale {
  .img {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    background: #fafafa;
  }
  .info {
    color: #333;
    font-size: 14px;
  }
}
</style>
